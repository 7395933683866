.order-content {
  padding-bottom: 100px;
  .title {display: flex; justify-content: space-between; align-items:baseline;
    p {font-size: 14px;}
  }
  .regular-title {display: flex; align-items:baseline; gap: 15px;}
  h2 {font-weight: 600; margin: 50px 0 15px; font-size: 20px;}
  .order-detail {
    border-top: 2px solid #222; text-align: center; border-bottom: 1px solid #222; font-size: 15px;
    &.expected {border-bottom: 1px solid #eee;}
    .row {padding: 15px 0; justify-content: center;}
    .img {
      img {width: 100%; height: 100%; object-fit: cover;}
    }
    .head {font-weight: 700; font-size: 16px; align-items: center;}
    .list {
      border-top: 1px solid #eee; font-size: 18px;
      .info {
        display: flex; flex-direction: column; gap: 10px;
        .mobile-list {flex-direction: column; gap: 5px; margin-top: 30px;}
      }
      &.price {font-weight: 700; font-size: 30px;}
    }
    @include media-breakpoint-down(lg) {
      .head {font-size: 16px;}
      .list {
        border-top: 1px solid #222; font-size: 15px;
        .thumb {order: 2;}
        .info {
          gap: 5px; order: 1;
        }
        .total {margin-top: 20px; font-size: 18px; text-align: center; order: 3; border-top: 1px solid #eee; padding-top: 10px;}
        &.price {font-size: 20px;}
      }
    }
  }
  .table {
    &.custom-table {
      tbody {
        th {background-color: transparent; border: 0; padding: 10px;}
        td {border: 0; padding: 10px;}
      }
    }
    @include media-breakpoint-up(lg) {
      &.custom-table {
        tbody {
          th {width: 10%;}
        }
      }
    }
  }
  .address-form {
    display: flex; flex-direction: column; gap: 7px;
    @include media-breakpoint-up(lg) {
      .input-form {
        input {width: 45%;}
      }
    }
  }
  .point {
    border-top: 2px solid #222; padding-top: 15px;
    .desc {color: #808080; font-size: 14px; margin-top: 5px; display: block;}
    @include media-breakpoint-down(lg) {
      input {width: 35%;}
    }
  }
  .payment {
    border-top: 2px solid #222; border-bottom: 1px solid #222; margin-bottom: 100px; font-size: 20px; justify-content: center;
    .right {background-color: rgb(255, 250, 251);}
    .radio-form {display: flex; flex-direction: column; gap: 20px; margin: 20px 0; justify-content: center; padding-left: 20px;}
    .form-check-label {font-size: 16px;}
    .txt-box {
      font-size: 40px; display: flex; flex-direction: column; gap: 5px; padding: 40px; align-items: end;
      .txt {font-weight: 700; width: 100%; text-align: end; padding-bottom: 10px; font-size: 16px;}
      .btn-box {
        margin-top: 30px; text-align: end; width: 100%;
        button {margin-bottom: 0; width: 100%;}
      }
      .txt-bottom {
        display: flex; font-size: 18px; gap: 10px;
        span {color: $blue;}
      }
    }
    @include media-breakpoint-down(lg) {
      font-size: 16px; border-bottom: 0;
      .radio-form {margin: 20px 0;}
      .form-check-label {font-size: 16px;}
      .txt-box {
        font-size: 28px; align-items: flex-start; padding: 20px; gap: 20px; align-items: center;
        .txt {text-align: center;}
        .btn-box {
          margin-top: 10px; text-align: center;
          button {margin-bottom: 30px;}
        }
        .txt-bottom {
          display: flex; font-size: 18px; gap: 10px;
          span {color: $blue;}
        }
      }  
    }
  }
  .add-card { display: flex; align-items: center; justify-content: center; border: 1px dashed #000; background-color: #F7F7F7; border-radius: 10px; padding: 15px 50px; color: #999; font-size: 15px;}
  .add-card-box {
    .table {border-bottom: 1px solid #eee;}
    td {font-weight: 700;}
    .card-name-box {display: flex; justify-content: space-between; align-items: center;}
    @include media-breakpoint-up(lg) {
      .table {
        &.custom-table {
          tbody {
            td {font-size: 16px;}
          }
        }
      }
      .card-name-box {
        .card-number {margin-left: 10px;}
      }
    }
  }
  .btn-group {margin-top: 100px;}

  @include media-breakpoint-up(lg) {
    h2 {font-size: 1.5em;}
    .order-detail {
      .row {align-items: center;}
    }
    input:not([type="radio"]), .input-icon, select {width: 30%;}
    textarea {width: 45%;}
  }
}