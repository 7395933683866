.content{
  .list-top {
    .tab-box {
      .table-header {
        display: flex; flex-direction: column; justify-content: center; margin-bottom: 40px; border-bottom: 1px solid $border-color; padding-bottom: 24px;
      }
    }
    @include media-breakpoint-up(lg) {
      .tab-box {
        .table-header {
          flex-direction: row; justify-content: space-between; 
        }
      }
    }
  }
  .list-wrap {
    padding-bottom: 50px;
    .table-header {justify-content: space-between;}
    .list-box {
      .card {
        border-radius: 16px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10); overflow: hidden;
        .thumb{
          position: relative;
          .img {
            &::before {
              position: absolute; bottom: 0; content: ''; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.04);
            }
            img {width: 100%; height: 100%; object-fit: cover;}
          }
        }
        .card-body {
          padding: 32px;
          .card-text {
            display: flex; justify-content: space-between; margin-bottom: 12px;
            & > div {align-items: center;}
            .age {padding: 1px 6px; color: $primary; background-color: #FEF4F5; font-size: 12px; font-weight: 700;}
            .season {padding: 1px 6px; color: #4698E3; background-color: #F0F7FD; font-weight: 700; font-size: 12px;}
            p {font-size: 14px; color: #666;}
            span {
              font-size: 14px; color: $gray;
              &.view {
                color: #666;
                &.like {display: flex; align-items: center; gap: 4px;}
              }
            }
          }
          .card-title {font-size: 18px; font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
          .card-desc {font-size: 16px; color: #999; margin-top: 12px; overflow: hidden; max-height: 3rem;
            span {font-size: 14px;}
          }
          .price-box {
            display: flex; justify-content: space-between; margin-top: 16px; align-items: center;
            p {font-size: 16px; color: #999; text-decoration: line-through;
            }
            strong {font-size: 20px; font-weight: 700;}
            .price {
              span {font-size: 18px; font-weight: 700; color: $danger-dark;}
            }
          }
        }
      }
      .thumb-img {
        border-radius: 16px; overflow: hidden;
        .img {
          position: relative;
          &::before {
            position: absolute; bottom: 0; content: ''; width: 100%; height: 100%; background: linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.10) 33.33%, rgba(0, 0, 0, 0.00) 66.67%, rgba(0, 0, 0, 0.06) 100%);
          }
          &.activity {
            height: 426px;
          }
          &.make {
            height: 214px;
          }
          &.design {height: 304px;}
          &.music {height: 170px;}
          img {width: 100%; object-fit: cover; height: 100%;}
          .thumb-title {
            position: absolute; left: 1rem; top: 1rem;  display: flex; justify-content: space-between; width: 85%;
            p {color: $white; font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 80%; font-size: 14px;}
          }
          .thumb-icon {
            position: absolute; right: 1rem; bottom: 1rem; border-radius: 50%; background: rgba(0, 0, 0, 0.30); width: 40px; height: 40px;
            i {margin: 9px;}
            &:hover {
              background-color: $danger-dark; transition: 0.15s ease-in-out;
            }
          }
        }
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30); transition: 0.15s ease-in-out;
        }
      }
      .item {
        margin-bottom: 15px;
        .img {
          border-radius: 16px; overflow: hidden;
          img{width: 100%; height: 100%; object-fit: cover;}
        }
        .title {
          margin-top: 12px; padding: 0 14px;
          p {font-size: 15px; font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
          }
          span {font-size: 16px; color: #999; margin-top: 12px; overflow: hidden; max-height: 1.6rem; display: inline-block; }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 200px;
      .table-header {
        .form-select {font-size: 16px;}
      }
      .list-box {
        .thumb-img {
          .img {
            .thumb-title {
              p {font-size: 16px;}
            }
            .chips {font-size: 13px;}
          }
        }
        .item {
          margin-bottom: 35px;
          .title {
            margin-top: 22px; padding: 0 16px;
            p {font-size: 18px;}
          }
        }
      }
      .navigation{margin-top: 195px;}
    }
  }
  .detail-desc {
    padding-bottom: 80px; border-bottom: 1px solid $border-color; margin-bottom: 40px;
    .img {
      img {width: 100%; height: 100%; object-fit: cover;}
    }
  }
  .btn-wrap {
    display: flex; justify-content: space-between; align-items: center; margin-bottom: 100px;
    div {display: flex; gap: 8px;}
  }
}

.chips {position: absolute; top: 1rem; right: 1rem; font-size: 12px; font-weight: 600; color: $white; background-color: rgba(0, 0, 0, 0.30); border-radius: 13px; padding: 3px 10px;
  &.category {
    top: unset; right: unset; bottom: 1rem; left: 1rem; font-size: 13px;
  }
}