*,
*::before,
*::after {
  box-sizing: border-box;min-width: 0;word-break: keep-all;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    // scroll-behavior: smooth;
  }
}

@media print {
  header, .mo-header-content, .sidebar, .common-depth, #footer {display: none;}
}

body {
  margin: 0;
  // font-family: $font-family-base;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: $line-height-base;
  color: $body-color;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 320px) {
  body {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6{
  margin: 0;font-weight: 400;line-height: 1.2;
}

p {
  margin: 0;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

dt {
  font-weight: 400;
}

dd {
  margin-left: 0;
}

blockquote {
  margin: 0;
}

b,
strong {
  font-weight: bolder;
}

em,
i {
  font-style: normal;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #a6f0b7;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: $body-color;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

figure {
  margin: 0;
}

img,
svg {
  vertical-align: middle;
  max-width: 100%;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

legend,
caption {
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  visibility: hidden;
  overflow: hidden;
  white-space: nowrap;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: 1em;
  line-height: 0;
  color: $body-color;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: none;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}