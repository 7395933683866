.content-head {
  width: 100%; padding: 100px 0; display: flex; flex-direction: column; align-items: center;
  &.order-complete {padding: 280px 0;}
  .content-title {
    font-size: 40px; font-weight: 700; text-align: center; line-height: 140%; color: #000;
  }
  @include media-breakpoint-down(lg) {
    padding: 40px 0;
    .content-title{font-size: 28px;}
  }
}