
.form-control {
  display: block; 
  width: 100%; 
  border: 1px solid $border-color; 
  font-size: 15px;
  line-height: 150%;
  color: #222;
  padding: 6px 10px;
  border-radius: 6px;
  caret-color: $primary;
  &.white{ background-color: #fff; border: 1px solid #ddd;}
  &::placeholder{ color: #999;}

  &:focus {
    outline: 1px solid #222;
    &::placeholder{ color: #222;}
  }
  &[type="file"] {
    margin-top: 10px;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    background-color: #fff; color: #ccc; font-weight: 400;
  }
  @include media-breakpoint-up(lg){
    font-size: 15px; padding: 9px 12px;
  }
}
.search-form{
  position: relative;
  .icon-search{
    position: absolute; right: 12px; top: 50%; transform: translateY(-50%);
  }
}
.form-password{
  position: relative;
  .view-pw{
    position: absolute; right: 12px; top: 50%; transform: translateY(-50%);
  }
}
.input-txt{
  color: $danger-dark; font-size: 14px; margin-top: 3px;
}

.input-form{
  .input-form-title{ margin-bottom: 5px;}
}
//  ID 찾기 -------
.certification-form{
  >div{ 
    display: flex; align-items: center; gap: 15px;
    .form-control{ flex-shrink: 1;}
    .btn{ flex-shrink: 0;}
  }
}
.time-form{
  position: relative; margin-top: 10px;
  .timer{
    position: absolute; right: 10px; top: 50%; transform: translateY(-50%); color: $danger-dark; font-size: 14px;
  }
}
.g-10{ gap: 10px;}
.g-20{ gap: 20px;}