.terms-content {
  line-height: 1.7; padding-bottom: 90px;
  .content-title {
    color: #555; font-size: 18px; padding: 50px 0 30px;
    h2 {font-weight: 500;}
    p {font-size: 16px; margin-top: 20px; font-weight: 500;}
  }
  .content-body{
    h3 {font-size: 24px; font-weight: 700; margin-top: 40px;}
    .desc {
      margin-top: 20px; color: #666;
      .gray-box {
        background-color: #f5f5f5; padding: 10px 20px; margin-top: 10px; line-height: 1.7;
      }
    }
    .desc-txt{
      margin: 40px 0 15px;
      .desc-title{
        color: #222; font-weight: 700; margin-bottom: 15px;
      }
      & > ul {padding-left: 5px;}
      li {
        color: #666; margin-bottom: 10px; font-size: 15px;
        .num {font-size: 11px; vertical-align: text-top;}
        p {
          margin-bottom: 15px; 
          &.txt {margin-top: 5px;}
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .content-title {
      font-size: 28px;
    }
    .content-body{
      h3 {font-size: 26px;}
      & > p {text-indent: 10px;}
      .desc {
        .gray-box{line-height: 2;}
      }
      .desc-txt {
        // li > span {margin-left: 25px;}
        .desc-title {font-size: 18px;}
        li {
          font-size: 16px;
          p {
            &.txt {padding-left: 35px;}
          }
        }
      }
      .pl-17px {padding-left: 17px;}
    }
  }
}

.text-wrap {
  p {color: #555; font-size: 17; font-weight: 500; margin-bottom: 10px;}
  textarea {border-color: $border-color; padding: 10px; color: #666; font-size: 14px; resize: vertical;
  }
  @include media-breakpoint-up(lg) {
    p {font-size: 18px;}
    textarea{font-size: 16px; padding: 10px 20px;}
    }
}