/* .content{
  .list-top {
    .tab-box {
      .table-header {
        display: flex; flex-direction: column; justify-content: center; margin-bottom: 40px; border-bottom: 1px solid $border-color; padding-bottom: 24px;
      }
    }
    @include media-breakpoint-up(lg) {
      .tab-box {
        .table-header {
          flex-direction: row; justify-content: space-between; 
        }
      }
    }
  }
  .list-wrap {
    padding-bottom: 200px;
    .list-box {
      .card {
        border-radius: 16px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10); overflow: hidden;
        .thumb{
          position: relative;
          .img {
            &::before {
              position: absolute; bottom: 0; content: ''; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.04);
            }
            img {width: 100%;}
          }
        }
        .card-body {
          padding: 32px;
          .card-text {
            display: flex; justify-content: space-between; margin-bottom: 12px;
            .age {padding: 1px 6px; color: $primary; background-color: #FEF4F5; font-size: 12px; font-weight: 700;}
            .season {padding: 1px 6px; color: #4698E3; background-color: #F0F7FD; font-weight: 700; font-size: 12px;}
            p {font-size: 14px; color: #666;}
            span {font-size: 14px; color: $gray;}
          }
          .card-title {font-size: 18px; font-weight: 600;}
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .table-header {
        .form-select {font-size: 16px;}
      }
      .list-box {
        .card {
          .card-body {
            .card-text {
              
              
            }
          }
        }
      }
    }
  }
} */