.tab-box {
  .table-header {
    display: flex; justify-content: space-between;
    .select-wrap {gap: 40px;}
    @include media-breakpoint-down(lg) {
      display: block; text-align: center;
      .type-1 {justify-content: center;}
      .icon-wrap {margin-top: 18px;}
    }
  }
  .icon-wrap {
    ul {
      display: flex; gap: 24px;
      li:nth-child(n+2) {
        position: relative;
        &::before {content: ''; height: 10px; width: 1px; background-color: $gray; left: -12px; display: block; position: absolute; top: 50%; transform: translateY(-50%);}
      }
    }
  }
  .tab-month {
    border: 1px solid $border-color; border-radius: 8px; margin-bottom: 16px; overflow: hidden;
    a {
      padding: 14px 16px;letter-spacing: -0.3px; font-size: 15px; color: $dark; line-height: 150%;
      strong {font-weight: 700; margin-right: 4px;}
      &:hover, &.active {color: $white; background-color: $primary; transition: all 0.2s ease-in-out;}
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    a:nth-child(-n+8) {border-bottom: 1px solid $border-color;}
    :nth-child(n+2):nth-child(-n+4) {border-left: 1px solid $border-color;}
    :nth-child(n+6):nth-child(-n+8) {border-left: 1px solid $border-color;}
    :nth-child(n+10):nth-child(-n+12) {border-left: 1px solid $border-color;}
    }
    /* @include media-breakpoint-down(lg) {
      a {letter-spacing: -0.28px; font-size: 14px;}
      :nth-child(n+3) {border-top: 1px solid $border-color;}
      :nth-child(even) {border-left: 1px solid $border-color;}
    } */
  }
  .tab-month-select {margin-bottom: 16px;}
  .tab-week {
    gap: 8px; margin-bottom: 60px;
    a{
      border: 1px solid $border-color; border-radius: 8px;  padding: 14px 0; font-size: 15px; line-height: 150%; text-align: center; margin-right: 4px; 
      strong {color: #666; font-weight: 700;}
      &:hover, &.active {
        color: $primary; border-color: $primary; transition: all 0.2s ease-in-out; font-weight: 600;
        strong{color: $primary; transition: all 0.2s ease-in-out; font-weight: 700;}
      }
      @include media-breakpoint-down(lg) {
        padding: 12px 0;
      }
    }
    @include media-breakpoint-down(lg) {
      overflow-x: scroll; flex-wrap: nowrap; margin-left: 0;
      a {width: 230px; flex: 0 0 auto;}
    }
  }
}

.table-wrap {margin-bottom: 134px;}