.search-page {
  .content-head {padding-top: 140px;}
  .search-bar{
    padding: 12px 0; background-color: #fff; width: 50%;
    .container{ display: flex; align-items: center; gap: 12px;}
    .form-wrap{
      width: 100%; border-radius: 50px; background-color: #fef4f5; display: flex; padding: 7px 8px; 
      .btn{ border-radius: 50px; flex: none;}
      .btn-search-del {padding-right: 16px; display: none;}
      .form-control{
        font-size: 18px; font-weight: 500; line-height: 140%; letter-spacing: -0.09px; border: none; background-color: transparent; padding-left: 24px;
        &:focus{outline: none;}
        &::placeholder{ color: #222; font-size: 18px;}
        &.filled ~ .btn-search-del{
          display: block;
        }
      }
    }
    @include media-breakpoint-down(lg){
      width: 100%;
      .form-wrap{
        .form-control{
          font-size: 16px; padding-left: 10px;
          &::placeholder{ color: #222; font-size: 16px;}
        }
      }
    }
  }

  .result-txt {
    padding: 12px 0 120px; color: #666; font-weight: 500; letter-spacing: -0.08px;
    .name {color: #000;}
    .num {color: $danger-dark;}
  }

  .scroll-tab {
    margin-bottom: 50px;
    .nav-tabs {white-space:unset;}
    @include media-breakpoint-up(lg) {
      width: 100%; margin-bottom: 84px;
    }
  }

  .list-wrap {
    margin-bottom: 50px;
    .hashtag-wrap {
      display: flex; gap: 8px; flex-wrap: wrap; margin-top: 50px;
      .hashtag {padding: 8px 20px; border-radius: 160px; background-color: #f7f7f7; color: #666; font-size: 15px; letter-spacing: -0.075px;}
      @include media-breakpoint-down(lg) {
        gap: 20px; margin-top: 30px; justify-content: center;
        .hashtag {font-size: 14px;}
      }
    }
    .list-box {
      display: flex; align-items: center; justify-content: space-between; gap: 30px; padding: 40px 0; flex-direction: column;
      .txt-box {
        display: flex; flex-direction: column; gap: 16px; order: 2; width: 100%;
        .title {font-size: 20px; font-weight: 700; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
        .desc {color: #666; font-size: 16px; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; -webkit-line-clamp: 3;}
        .breadcrumb {display: flex; align-items: center; gap: 4px; font-size: 16px; font-weight: 500;
        .text-dangerdark {color: $danger-dark;}
        }
      }
      .img-box {
        min-width: 192px; border-radius: 16px; overflow: hidden; order: 1;
        img {width: 100%; height: 100%; object-fit: cover;}
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row; gap: 100px;
        .txt-box {
          order: 1;
          .title {font-size: 24px;}
          .desc {font-size: 18px;}
  
        }
        .img-box {order: 2;}
      }
    }
    .board {
      li:nth-child(n+2) {border-top: 1px solid #eee;}
    }  
  }

  .btn-group {
    .btn {border-radius: 40px; opacity: 0.8; display: flex; align-items: center; justify-content: center; gap: 8px;
      span {color: #000; letter-spacing: -0.08px;}
      .icon-default-arrow {transform: rotate(90deg);}
    }
  }
  @include media-breakpoint-down(lg) {
    .content-head {padding: 70px 0 0;}
    .result-txt {padding-bottom: 80px; font-size: 14px;}
  }
}