#main{
  padding-bottom: 240px;
  @include media-breakpoint-down(lg){
    padding-bottom: 120px;
  }
}
section{ 
  padding-top: 140px;
  .section-title{
    text-align: center; color: #000; font-size: 28px; font-weight: 800; line-height: 150%;
  }
  .section-subtitle{
    text-align: center; font-size: 18px; font-weight: 500; line-height: 150%; margin-top: 8px;
  }
  @include media-breakpoint-down(lg) {
    padding-top: 70px;
    .section-title{ font-size: 24px; }
    .section-subtitle{ font-size: 16px;}
  }
}
//스와이퍼
.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .swiper-tool-wrap {
    width: 100%; position: absolute;  left: 0;
    >.container{display: flex; gap: 21px;}
    .swiper-pagination {width: auto; bottom: 0; text-align: start; position: relative; bottom: 2px;}
    .swiper-pagination-bullet {
      width: 6px; height: 6px; background-color: #000; opacity: 1;
      &-active {
        width: 32px; height: 6px; border-radius: 3px;
      }
    }
    .swiper-button-wrap {
      display: flex; gap: 8px;
      .swiper-button-next, .swiper-button-prev {
        width: 24px; height: 24px; right: 0; position: relative; margin: 0; top: 0;
        &::after{ content: none;}
      }
    }
    .swiper-button-prev {
      left:0;
    }
  }
}
.more-btn{
  display: flex; align-items: center; justify-content: end; gap: 4px; font-size: 15px; font-weight: 500; line-height: 150%; margin-bottom: 20px;
}
//메인 베너
.main-banner {
  .swiper-container {
    height: 600px;
    .swiper-slide {
      .banner-wrap {
        position: relative;height: 100%; width: 100%;
        .img {
          height: 100%; width: 100%;
          img {display: block; width: 100%; height: 100%; object-fit: cover; position: static;}
        }
        .desc-wrap {
          width: 100%; position: absolute; top: 107px; left: 0;
          p {
            font-size: 18px; font-weight: 700; line-height:120%;
            &.title {font-size: 48px; font-weight: 600; line-height: 120%; margin-top: 16px;}
            &.desc {font-size: 24px; line-height: 150%; margin-top: 60px;}
          }
        }
      }
    }
    .swiper-tool-wrap { bottom: 20%; left: 0;}
  }
  @include media-breakpoint-down(lg) {
    .swiper-container {
      height: 450px;
      .swiper-slide {
        .banner-wrap {
          .desc-wrap {
            left: 0;
            p {
              font-size: 16px;
              &.title {font-size: 36px; margin-top: 12px;}
              &.desc {font-size: 20px; margin-top: 30px;}
            }
          }
        }
      }
      .swiper-tool-wrap { left: 0;}
    }
  }
}
// 이벤트 띠 배너
.event-banner{
  background-color: #222;
  >.container{
    display: flex; align-items: center; justify-content: space-between;
  }
  ul{
    display: flex; align-items: center; padding: 16px 0;
    li{ 
      padding:  0 80px; position: relative;
      &::after{
        content: ""; position: absolute; width: 1px; height: 24px; right: 0; top: 50%; transform: translateY(-50%); background-color: rgba(255, 255, 255, 0.40);
      }
      &:last-child{
        &::after{content:none;}
      }
      a{ color: #fff; font-weight: 700; line-height: 150%;}
    }
  }
  .btn-group{ margin-top: 0;}
  .btn{ border-radius: 42px;}
  @include media-breakpoint-down(lg) {
    >.container{
      flex-direction: column;
    }
    ul{
      flex-direction: column; 
      li{ 
        padding: 5px 0;
        &::after{
          content: none;
        }
      }
    }
    .btn-group{ padding-bottom: 20px;}
  }
}
// 카테고리
.category-wrap{
  padding-top: 60px;
  ul{
    display: flex;  gap: 40px; justify-content: center; 
    .img{
      width: 88px; height: 88px; border-radius: 50%; background-color: #f7f7f7; display: flex; align-items: center; justify-content: center; margin: 0 auto;
    }
    .desc{font-size: 16px; font-weight: 500; line-height: 150%; margin-top: 10px; text-align: center;}
  }
  @include media-breakpoint-down(lg) {
    padding-top: 30px;
    ul{
      flex-flow: wrap; gap: 20px;
      .img{
        width: 60px; height: 60px; padding: 15px;
      }
      .desc{font-size: 14px;}
    }
  }
}
// 이달의 추천놀이
.suggestion-wrap{
  .nav-tabs{
    justify-content: center; margin-top: 40px;
    @include media-breakpoint-down(lg) {
      justify-content: start; margin-top: 20px;
    }
  }
  .swiper-play{
    margin-top: 80px;
    .swiper-container{margin-top: 20px; padding-bottom: 60px;}
    .swiper-slide{
      height: 240px;
      .content{
        width: 100%; height: 100%; border-radius: 24px; display: flex; align-items: center; justify-content: center; padding: 36px;
        p{text-align: center; font-size: 24px; font-weight: 700; line-height: 150%;}
      }
    }
    @include media-breakpoint-down(lg) {
      margin-top: 40px;
      .swiper-container{ padding-bottom: 30px;}
      .swiper-slide{
        height: 240px;
        .content{
           padding: 36px;
          p{font-size: 20px; }
        }
      }
    }
  }
  .card-wrap{
    display: flex; gap: 32px; margin-top: 140px;
    .card-page{
      width: calc(50% - 16px); height: 200px; padding: 40px 40px 25px 40px; display: flex; flex-direction: column; justify-content: space-between; border-radius: 16px; background-color: #F2F4E8;
      background-image: url('../images/main-card-01.svg'); background-repeat: no-repeat;     background-position: 80% 90%; 
      &.second{ background-color: #F0F5F8;  background-image: url('../images/main-card-02.png'); background-repeat: no-repeat; background-size: 40%; background-position: 90% 90%; }
      .desc{
        color: #000; font-size: 18px; line-height: 150%;
      }
      .title{
        color: #000; font-size: 28px; font-weight: 800; line-height: 150%; margin-top: 4px;
      }
      .more-btn{
        justify-content: start; margin-bottom: 0;
      }
    }
    @include media-breakpoint-down(lg) {
      flex-flow: column; margin-top: 70px; gap: 16px;
      .card-page{
        width: 100%; padding: 20px 20px 13px 20px;
        .desc{ font-size: 16px; }
        .title{ font-size: 24px; }
      }
    }
  }
  .video-wrap{
    margin-top: 200px; position: relative; z-index: 1; padding-bottom: 60px;
    &::after{
      content: ""; position: absolute; width: 100%; height: 70%; left: 0; bottom: 0; background-color: #f7f7f7; z-index: -1;
    }
    .container{ display: flex; align-items: center; gap: 60px;}
    .video-content{
      width: 70%;
      iframe{ width: 100%; height: 445px; border-radius: 32px;}
    }
    .text-box{
      text-align: center;
      .ball{ width: 80px; height: 80px; background-color: #d9d9d9; border-radius: 50%; margin: 0 auto;}
      .title{color: #000; font-size: 24px;font-weight: 800; line-height: 150%; margin-top: 24px; }
      .desc{ font-size: 18px; font-weight: 500; line-height: 150%; margin-top: 40px; padding: 0 40px;}
    }
    @include media-breakpoint-down(lg) {
      margin-top: 100px; padding-bottom: 40px;
      .container{ flex-direction: column; gap: 30px;}
      .video-content{
        width: 100%;
        iframe{height: 250px;}
      }
      .text-box{
        .ball{ width: 60px; height: 60px; }
        .title{ font-size: 20px;  margin-top: 15px; }
        .desc{ font-size: 16px; margin-top: 20px; padding: 0 20px;}
      }
    }
  }
}

// 상상 스토리샵
.story-shop{
  .swiper-story-shop{ margin-top: 45px; }
  .shop-content-list{ display: flex; flex-flow: wrap; gap: 22px; }
  @include media-breakpoint-down(lg) {
    .swiper-story-shop{ margin-top: 30px; }
  }
  .shop-content{
    width: calc(25% - 18px); box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1); border-radius: 16px;
    .img{
      width: 100%; height: 300px; max-height: 300px;
      img{ width: 100%; height: 100%; object-fit: cover; border-radius: 16px 16px 0 0;}
    }
    @include media-breakpoint-down(xl) {
      width: calc(50% - 12px);
      .img{
        width: 100%; height: 300px; max-height: 300px;
      }
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      .img{
        width: 100%; height: 250px; max-height: 250px;
      }
    }
    .desc{
      padding: 32px;
      @at-root{
        .badge{
          font-size: 12px; font-weight: 700; line-height: 150%; color: $white; background-color: $primary;     padding: 1px 5px; text-align: center; display: inline-block; margin-right: 4px;
          &.best{ background-color: #F68655;}
          &.outline-dark{
            border: 1px solid #222; color: #222; border-radius: 42px; background-color: #fff;
          }
        }
      }
      .company{
        .company-name{
          font-size: 14px; line-height: 150%; color: #666;
        }
      }
      .product-name{
        font-size: 18px; font-weight: 600; line-height: 150%; margin-top: 12px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
      }
      .price-wrap{
        display: flex; align-items: center; justify-content: space-between; margin-top: 20px;
        .sale{
          font-size: 16px; line-height: 150%; text-decoration: line-through; color: #999;
        }
       .box{
        display: flex; align-items: center; gap: 4px;
        .percent{ font-size: 18px; font-weight: 700; line-height: 150%; color: $danger-dark; }
        .total-price{ font-size: 20px; font-weight: 700; line-height: 150%; }
       }
      }
      @include media-breakpoint-down(md) {
        padding: 20px;
        .company{
          .company-name{ font-size: 13px; }
        }
        .product-name{
          font-size: 16px; margin-top: 8px;
        }
        .price-wrap{
          margin-top: 15px;
          .sale{ font-size: 14px; }
          .box{
          .percent{ font-size: 16px;}
          .total-price{ font-size: 18px;}
          }
        }
      }
    }
  }
}
// 특색프로그램
.feature-program{
  .feature-program-content{
    width: 100%; height:360px; display: flex; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);  padding: 0;
    border-radius: 24px;
  }
  .banner{
    width: 40%; background-color: #F68655; display: flex; align-items: center; justify-content: center; border-radius: 24px 0 0 24px; padding: 0 25px;
  }
  .swiper-program{
    width: 60%;padding: 60px 48px 50px 0;
    .content{
      width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: space-between; border-radius: 0 24px 24px 0; background-color: #fff; padding-left: 112px;
      .title{color: #000; font-size: 24px; font-weight: 800; line-height: 150%;}
      .desc{color: #000; font-size: 16px; line-height: 150%; margin-top: 8px;}
      .more-btn{
        justify-content: start; margin-bottom: 0;
      }
    }
    .swiper-tool-wrap{
      left: inherit; right: 0; bottom: 0;
      >.container{ padding: 0; justify-content: end;}
    }
  }
  @include media-breakpoint-down(lg) {
    .feature-program-content{
      width: 100%; height: 450px; display: block; 
    }
    .banner{
      width: 100%; height: 200px; border-radius: 24px 24px 0 0; padding: 0 25px;
    }
    .swiper-program{
      width: 100%; height: 250px; padding: 30px 24px 25px 0;
      .content{
        width: 100%; height: 100%; border-radius: 0 0 24px 24px; padding-left: 24px; padding-bottom: 30px;
        .title{font-size: 20px;}
        .desc{font-size: 14px; margin-top: 8px;}
        .more-btn{
          justify-content: start; margin-bottom: 0;
        }
      }
    }
  }
}

// 상상 리뷰
.story-review{
  .swiper-story-review{
    margin-top: 45px;
  }
  .review-content-list{
    display: flex; flex-flow: wrap; gap: 22px;
  }
  .review-content{
    width: calc(25% - 18px); box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1); border-radius: 16px;
    .img{
      width: 100%; height: 300px; max-height: 300px;
      img{ width: 100%; height: 100%; object-fit: cover; border-radius: 16px 16px 0 0;}
    }
    @include media-breakpoint-down(xl) {
      width: calc(50% - 12px);
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      .img{ width: 100%; height: 250px; max-height: 250px; }
    }
    .desc{
      padding: 32px;
      .title{font-size: 18px; font-weight: 600; line-height: 150%;}
      .txt{
        font-size: 16px; font-weight: 600; line-height: 150%; color: #999; margin-top: 16px; overflow: hidden; text-overflow: ellipsis; white-space: normal;  display: -webkit-box;
        -webkit-box-orient: vertical; -webkit-line-clamp: 3;
      }
    }
    &.card{
      background-color: #F68655; position: relative; padding: 32px;
      .card-title{font-size: 24px; font-weight: 700; line-height: 150%; color: #fff;}
      .card-txt{
        font-size: 18px; font-weight: 500; line-height: 150%; color: #fff; margin-top: 7px;
      }
      i{ position: absolute; bottom: 32px; right: 32px; }
    }
    @include media-breakpoint-down(md) {
      .desc{
        padding: 20px;
        .title{font-size: 16px;}
        .txt{ font-size: 14px;margin-top: 10px; }
      }
      &.card{
        height: 200px; padding: 20px;
        .card-title{font-size: 20px;}
        .card-txt{ font-size: 16px; }
        i{ position: absolute; bottom: 20px; right: 20px; }
      }
    }
  }
}
// 공지사항
.announcement-wrap{
  .nav-tabs{
    justify-content: center; margin-top: 40px;
  }
  @include media-breakpoint-down(lg){
    .nav-tabs{
      margin-top: 20px;
    }
  }
}
.announcement-content{
  margin-top: 80px;
  .announcement-content-head{
    display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px;
    .more-btn{margin-bottom: 0;}
    .txt{
      display: flex; align-items: center; gap: 8px;
      .date{ font-weight: 800;}
      .object{font-weight: 500;}
    }
  }
  .announcement-list{
    display: flex; align-items: center; flex-flow: wrap; gap: 32px;
  }
  .announcement-box{
    width: calc(25% - 24px); padding: 32px; background-color: #f7f7f7; border-radius: 8px;
    .title{font-size: 18px; font-weight: 600; line-height: 150%; overflow: hidden; text-overflow: ellipsis; white-space: normal;  display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; margin-top: 16px;}
    .desc{
      font-size: 16px; font-weight: 600; line-height: 150%; color: #999; margin-top: 16px; overflow: hidden; text-overflow: ellipsis; white-space: normal;  display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3;
    }
    .date{
      font-size: 18px; font-weight: 800; line-height: 150%; display: flex; align-items: center; gap: 8px;
    }
  }
  @include media-breakpoint-down(xl){
    .announcement-box{
      width: calc(50% - 16px);
    }
  }
  @include media-breakpoint-down(md){
    margin-top: 40px;
    .announcement-list{ gap: 20px; }
    .announcement-content-head{
      flex-direction: column; align-items: end;
      .more-btn{ margin-bottom: 30px;}
      .txt{ flex-flow: wrap; gap: 5px; order: 2; }
    }
    .announcement-box{
      width: 100%; padding: 20px 20px 40px;
      .title{font-size: 16px; margin-top: 10px;}
      .desc{ font-size: 14px; margin-top: 10px;  }
    }
  }
}
// sns
.swiper-sns{
  margin-top: 40px;
  .img{ width: 100%; height:0; padding-bottom: 100%; background-size: cover; background-position: center; border-radius: 16px;}
}