footer{
  .footer-head{
    border-top: 1px solid $border-color; border-bottom: 1px solid $border-color; padding: 16px 0; 
    >.container{
      display: flex; align-items: center; justify-content: space-between; 
    }
    .question-desc{
      display: flex; align-items: center; gap: 6px;
      p{ font-size: 13px; font-weight: 600; line-height: 150%;}
      .call-number{ color: $primary; }
      .day{ font-size: 12px; font-weight: 400; color: #666;}
    }
    @include media-breakpoint-down(lg){
      >.container{
        flex-direction: column; align-items: start;
        .question-desc{
          flex-wrap: wrap; align-items: start;
        }
      }
      
    }
    @include media-breakpoint-up(lg){
      .question-desc{
        p{font-size: 15px;}
        .day{ font-size: 14px;}
      }
    }
    .link{
      ul{
        display: flex;
        li{
          position: relative;
          &::after{
            content:""; position: absolute; width: 1px; height: 10px; background-color: #ccc; right:  -16px; top: 50%; transform: translateY(-50%);
          }
          &:last-child{
            &::after{content:none;}
          }
          a{font-size: 12px; font-weight: 600; line-height: 160%;}
        }
      }
      @include media-breakpoint-up(lg){
        ul{
          display: flex; gap: 36px;
          li{
            a{font-size: 14px;}
          }
        }
      }
    }
  }
  .footer-content{
    display: flex; justify-content: space-between; align-items: baseline;
    .info-list{
      ul{ 
        display: flex; align-items: center; gap: 16px;
        li{ 
          font-size: 12px; line-height: 150%; color: #666;
          .name{color: #222; font-weight: 700;}
        }
      }
    }
    .company{
      font-size: 12px; line-height: 150%; color: #666; margin-top: 12px;
    }
    .sns-list{
      display: flex; align-items: center; justify-content: center; gap: 10px;
    }
    .copyright{
      font-size: 12px; color: #999; line-height: 150%; margin-top: 10px;
    }
    @include media-breakpoint-down(lg){
      flex-direction: column; padding: 24px 0;
      .info-list{
        ul{
          flex-wrap: wrap; gap: 8px;
        }
      }
      .sns-list{
        margin-top: 15px;
      }
    }
    @include media-breakpoint-up(lg){
     padding: 40px 0 53px;
     .info-list{
        ul{ 
          gap: 16px;
          li{ 
            font-size: 14px;
          }
        }
      }
      .company{
        font-size: 14px; margin-top: 16px;
      }
      .copyright{
        font-size: 14px; margin-top: 18px;
      }
    }
  }
}
.util-btn{
  display: none;
  @include media-breakpoint-down(lg){
    display: flex; flex-direction: column; gap: 8px; position: fixed; bottom: 24px; right: 16px; z-index: 9999;
    .mo-btn{
      width: 48px; height: 48px; border: 1px solid $border-color; border-radius: 50%; display: flex; align-items: center; justify-content: center; box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.3); background-color: #fff; 
      .notice{
        position: relative;
        &.active{
          &:after{
            content:""; width: 6px; height: 6px; border-radius: 50%; background-color: $danger-dark; position: absolute; transform: translateX(-50%); z-index: 1;    top: -4px;
            right: -4px;
          }
        }
      }
    }
  }
}