// default가 md
.btn{
    width: 160px;
    display: inline-block;
    font-weight: 600;
    color: #000;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 9px 0;
    font-size: 15px;
    line-height: 150%;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &.btn-auto{
      width: auto; 
      &.btn-sm{
        padding: 5px 16px; font-size: 14px;
      }
      &.btn-md{
        padding: 9px 24px; font-size: 14px;
      }
      &.btn-lg{
        padding: 14px 32px; font-size: 16px;
      }
    }
    &.btn-sm{
      padding: 5px 0; font-size: 14px;
    }
    &.btn-lg{
      padding: 14px 0; font-size: 16px;
      &.round{ border-radius: 8px;}
    }
    @include media-breakpoint-down(lg){
      padding: 8px 13px; font-size: 14px;
      &.btn-auto{
        &.btn-sm{
          padding: 4px 16px; font-size: 12px;
        }
        &.btn-md{
          padding: 5px 18px; font-size: 12px;
        }
        &.btn-lg{
          padding: 7px 21px; font-size: 14px;
        }
      }
      &.round-btn{
        &.plus{ width: 30px; height: 30px;}
        &.minus{ width: 30px; height: 30px;}
      }
      &.btn-md{
        max-height: 44px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
      }
      &.btn-lg{
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
      }
    }
    &.btn-primary{
      background-color: $primary !important;
      color: $white !important;
    }
    &.btn-outline-primary{
      background-color: $white !important;
      border: 1px solid $primary !important;
      color: $primary !important;
    }
    &.btn-secondary{
      background-color: $secondary !important;
      color: $primary !important;
    }
    &.btn-dark{
      background-color: $dark !important;
      color: $white !important;
    }
    &.btn-outline-dark{
      background-color: $white !important;
      border: 1px solid $dark !important;
      color: $dark !important;
    }
    &.btn-gray{
      background-color: $gray !important;
      color: $white !important;
    }
    &.btn-outline-gray{
      background-color: $white !important;
      border: 1px solid $gray !important;
      color: $gray !important;
    }
    &.btn-gray-dark{
      background-color: #999 !important;
      color: $white !important;
    }
    &.btn-outline-white{
      background-color: transparent !important;
      border: 1px solid $white !important;
      color: $white !important;
    }
    &.round{ border-radius: 4px;}
    &.btn-outline-danger-dark {
      border: 1px solid $danger-dark !important;
      color: $danger-dark !important;
      border-radius: 24px;
    }
    &.btn-outline-black {
      border: 1px solid #222 !important;
      color: #000 !important;
      border-radius: 24px;
    }

   

   
  
}