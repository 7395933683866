html.bg-gray{
  height: 100%; background: #F7F7F7;
  body{ height: 100%;}
}
#wrap{
  &.login{
    padding-top: 0; 
    @include media-breakpoint-down(lg){
      height: 100%;
    }
  }
}


//  로그인 -------
.sign-container{
  width: 100%; height: 100%;
  .content{
    width: 100%; margin: 0 auto; background-color: #fff; border: 1px solid $border-color; border-radius: 8px;
  }
  @include media-breakpoint-up(lg){
    max-width: 800px; margin: 0 auto; padding: 200px 0 120px;
   
  }
  @include media-breakpoint-down(lg){
    .content{ height: 100%; padding: 60px 0;}
  }

  // 전페이지 공용 ---
  .login-head{
    padding: 16px; border-bottom: 1px solid $border-color;
    .top-head{
      display: flex; align-items: center; justify-content: center;  position: relative;
      .back{position: absolute; top: 50%; left: 0; transform: translateY(-50%);}
    }
    .sign-title{
      text-align: center; margin-top: 0; font-weight: 700;
    }
    @include media-breakpoint-up(lg){
      padding: 30px;
      .top-head{
        .back{display: none;}
      }
      .sign-title{
        font-size: 28px; margin-top: 16px;
      }
    }
  }
  .text-box{
    margin-bottom: 30px;
    p{ margin-top: 15px; text-align: center;}
  }


  // 전페이지 공용 end ---
  .sign-content{
    padding: 0 30px;
    @at-root{
      .input-form{
        padding-top: 24px;
      }
      .btn-group{
        margin-top: 24px; display: flex; flex-direction: column; align-items: center; gap: 10px;
        @include media-breakpoint-up(lg){
          margin-top: 67px; gap: 15px;
        }
      }
    }
    .login-btn{
      width: 100%; border: 2px solid $border-color; border-radius: 24px; font-size: 16px; color: #a3a3a3;
    }
    .save-login{
      margin-top: 10px;
    }
    @include media-breakpoint-up(lg){
      padding: 50px 160px 80px;
      .save-login{
        margin-top: 20px;
      }
    }
    .link-login{
      margin-top: 40px; display: flex; align-items: center; justify-content: flex-end; gap: 16px;
      li{
        position: relative;
        a{
          font-size: 12px;
        }
        &+li{
          &::after{ 
            content: ""; position: absolute; width: 1px; height: 45%; background-color: #bbb; left: -8px; top: 55%; transform: translateY(-50%);
          }
          
        }
      }
      @include media-breakpoint-up(lg){
        li{
          a{ font-size: 16px; }
          &+li{
            &::after{  height: 60%;  top: 50%; }
          }
        }
      }
    }
    .login-type{
      margin-top: 20px;
      ul{
        display: flex; flex-direction: column; gap: 10px;
        li{
          a{
            display: block; padding: 15px 10px 15px 10px; background-repeat: no-repeat; background-position: 5px center; border-radius: 50px; text-align: center; font-size: 14px;
          }
          &.kakao{
            a{ background-color: #fee500; background-image: url(../images/icon_kakao.png); }
          }
          &.naver{
            a{ background-color: #00c43b; background-image: url(../images/icon_naver.png);  color: #fff; }
          }
          &.google{
            a{ background-color: #4285f4; background-image: url(../images/icon_google.png); color: #fff; }
          }
          &.apple{
            a{ background-color: #000; color: #fff; background-size: 34px; background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_374_6206)'%3E%3Cpath d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.2669 9.59998C29.5182 11.3066 28.8235 12.9785 27.9072 14.1614C26.9268 15.4303 25.2369 16.4116 23.5996 16.3604C23.3007 14.7265 24.066 13.043 24.9972 11.9113C26.0186 10.6623 27.7692 9.70406 29.2669 9.59998ZM34.1854 33.6216C35.0295 32.328 35.3448 31.6754 36 30.2149C31.2343 28.4026 30.4707 21.6273 35.1871 19.0269C33.7485 17.2228 31.727 16.177 29.8187 16.177C28.4437 16.177 27.5016 16.5358 26.6452 16.862C25.9315 17.1339 25.2772 17.383 24.4815 17.383C23.6216 17.383 22.8601 17.11 22.0628 16.8241C21.1866 16.51 20.2672 16.1803 19.1262 16.1803C16.9848 16.1803 14.7054 17.4888 13.2602 19.7257C11.2288 22.8763 11.5753 28.7991 14.8696 33.8446C16.0471 35.6504 17.6203 37.6792 19.678 37.6973C20.5318 37.7058 21.1 37.451 21.7148 37.1752C22.4186 36.8596 23.1834 36.5165 24.5078 36.5095C25.8399 36.5015 26.5927 36.8487 27.2871 37.169C27.8862 37.4453 28.4417 37.7016 29.2883 37.6924C31.3476 37.6759 33.0079 35.4273 34.1854 33.6216Z' fill='%230B0B0A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_374_6206'%3E%3Crect width='48' height='48' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");}
          }
        }
      }
      @include media-breakpoint-up(lg){
        margin-top: 40px;
        li{
          a{
            background-position: 10px center; text-align: center; font-size: 16px;
          }
        }
      }
    }
  }
 
 
}




// ID 찾기 완료
.find-result{
  text-align: center; font-size: 18px; margin: 50px 0px;
  strong{ font-size: 20px;}
}

// 회원가입
.signup-title{
  font-size: 20px; font-weight: 700; margin-top: 40px;
}
.radio-form{
  margin-top: 10px;
}

.agree-form{
  .all-check{
    margin-top: 15px;
    .form-check-input{ width: 24px; height: 24px; }
    .form-check-label{ font-size: 16px; }
  }
  .desc{
    font-size: 12px; margin-top: 10px;
  }
  .agree-content{
    margin-top: 20px;
    .txt-box{
      margin-top: 10px; border: 1px solid $border-color; padding: 10px 15px;
      height: 100px; overflow: auto; font-size: 12px;
    } 
  }
}