.content-area {
  border-radius: 16px; position: relative; width: 100%; height: 100px; overflow: hidden; margin-bottom: 30px; background-color: #eee; display: flex; align-items: center; justify-content: center;
  &.play {height: 140px;}
  img {width: 100%; height: 100%; position: static; object-fit: cover;}
  strong {font-weight: 500; color: #ccc; font-size: 18px;}
  @include media-breakpoint-up(lg) {
    height: 200px; margin-bottom: 80px;
    &.play {height: 275px;}
  }
}

.content {
  .list-wrap {
    .img-box {
      border-radius: 8px; overflow: hidden;
      img {width: 100%; height: 100%; object-fit: cover;}
    }
    .round-img {
      border-radius: 50%; background-color: #EEE; width: 160px; height: 160px; overflow: hidden; display: flex; align-items: center; justify-content: center;
      span {color: #ccc; font-weight: 500; }
      img {width: 100%; height: 100%; object-fit: cover;}
    }
    .play-card {
      div {display: flex; justify-content: center;}
    }
  }
}