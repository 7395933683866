.detail {
  padding: 40px 0;
  &.inquiry {
    padding: 0;
    &.content {
      .btn-wrap {justify-content: center; gap: 6px;}
    }
  }
  .content-top {
    margin-bottom: 65px;
    .content-box {
      .img {
        position: relative; border-radius: 24px; border-radius: 24px; overflow: hidden;
        &::before {
          position: absolute; bottom: 0; content: ''; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.04);
        }
        img {width: 100%; height: 100%; object-fit: cover;}
      }
      .content-desc {
        margin-top: 45px;
        .title {
          color: #000; font-size: 25px; font-weight: 700; line-height: 130%; margin-bottom: 24px;
        }
        .desc {font-size: 16px; margin-bottom: 24px; overflow: hidden; max-height: 3.1rem;}
        .hashtag-wrap {
          display: flex; gap: 8px; margin-bottom: 40px; flex-wrap: wrap;
          .hashtag {border-radius: 24px; background-color: #f7f7f7; padding: 4px 12px; color: #666; font-size: 11px;font-weight: 500; line-height: 24px;}
        }
        .text-wrap {
          display: flex; flex-direction: column; gap: 13px;
          .text {
            display: flex;
            span {width: 100px; flex: 0 0 100px; font-size: 15px; color: #666;}
            strong {flex: 1 1 100%; max-width: 100%; font-size: 15px; font-weight: 500;}
          }
        }
        .price-box {
          display: flex; padding: 16px 0; border-top: 1px solid $border-color; border-bottom: 1px solid $border-color; align-items: center; justify-content: space-between; margin: 30px 0;
          p {font-size: 16px; font-weight: 600;}
          .num-wrap {
            display: flex; align-items: center; gap: 20px;
            span {font-size: 16px; color: #999; text-decoration: line-through;}
            .price {
              display: flex; gap: 10px; align-items: center;
              p {font-size: 20px; color: $danger-dark; font-weight: 700;}
              strong {color: #000; font-size: 20px; font-weight: 700; line-height: 130%;}
            }
          }
        }
        button {margin-top: 40px;}  
        @include media-breakpoint-up(lg) {
          margin-top: 0;
          .title {font-size: 28px;}
          .desc {font-size: 18px;}
          .hashtag-wrap {
            margin-bottom: 60px;
            .hashtag {font-size: 14px;}
          }
          .text-wrap {
            .text {
              span {width: 112px; flex: 0 0 112px;}
            }
          }
          .price-box {
            padding: 26px 0;
            .num-wrap {
              gap: 60px;
              span {font-size: 18px;}
              .price {
                gap: 16px;
                p, strong {font-size: 24px;}
              }
            }
          }
        }
      }
      &.sub-content {
        .content-desc {
          .title {margin-bottom: 16px; font-size: 21px;}
          .desc {margin-bottom: 16px; font-size: 14px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
          .hashtag-wrap {margin-bottom: 19px;}
          @include media-breakpoint-up(lg) {
            .title {font-size: 24px;}
            .desc {font-size: 16px;}
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 167px;
    }
  }
  .scroll-tab {
    &.sticky {
      z-index: 9999; position: sticky; top: 0; background-color: $white;
      &.scroll-up {
        z-index: 99; top: 120px;
      }
    }
    @media screen and (max-width: 1300px) {
      &.sticky {
        top: 0;
        &.scroll-up {
          top: 60px;
        }
      }
    }
  }
  .detail-desc {
    border: 0; margin-top: 80px; 
    &.board {border-bottom: 1px solid $border-color; margin-top: 0;}
  }
  @include media-breakpoint-up(lg) {
    padding: 0 0 250px;
    &.product {padding-top: 130px;}
  }
  .desc-title {
    font-size: 20px; color: #000; font-weight: 700; line-height: 140%; padding: 22px 0; border-top: 2px solid #222; border-bottom: 1px solid $border-color; margin-bottom: 16px;
    i {margin-left: 16px;}
  }
  .sub {
    font-size: 14px; font-weight: 500; display: flex; gap: 24px; align-items: center; margin-bottom: 40px;
    span {color: #999;}
    strong {font-weight: 500; margin-left: 8px;}
  }
  .desc-txt {font-size: 16px; color: #000; line-height: 180%;}
  @include media-breakpoint-up(lg) {
    .desc-title {
      font-size: 28px; padding: 32px 0;
    }
    .sub {font-size: 16px; margin-bottom: 80px;}
    .desc-txt {font-size: 18px;}
  }
}