.event {
  .list-wrap {
    margin-top: 100px; padding: 0 20px 200px;
    .event-box {
      border-radius: 16px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10); overflow: hidden;  margin-bottom: 40px;
      .event-img {
        padding: 0;
        img {width: 100%; height: 100%; object-fit: cover;}
      }
      .event-desc {
        padding: 32px 56px;
        p {color: $danger-dark; font-size: 14px; font-weight: 700; line-height: 24px; margin-bottom: 4px;}
        strong {font-size: 24px; font-weight: 600;}
        .date-wrap {
          display: flex; align-items: center; justify-content: space-between; margin-top: 51px;
          span {color: #999;}
          .btn {border-radius: 24px;}
        }
        @include media-breakpoint-down(lg) {
          padding: 16px 12px;
          strong {font-size: 20px;}
          .date-wrap {
            margin-top: 20px;
            span {font-size: 12px;}
          }
        }
      }
    }
  }
}